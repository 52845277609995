<script setup lang="ts">
import { defu } from 'defu'
import type { PopperOptions } from '#ui/types'

const props = withDefaults(defineProps<{
  modelValue?: boolean
  mode?: 'hover' | 'click'
  popper?: PopperOptions
}>(), {
  modelValue: false,
  mode: 'click',
})

const ui = {
  /**
   * Arrows are buggy on hover, they seem to be jumping around - I can't find a solution.
   */
  arrow: {
    base: 'z-10 before:w-4 before:h-4 before:relative before:shadow-none',
    ring: 'before:ring-0',
    background: 'before:bg-forgd-primary-900',
    shadow: 'before:shadow-none',
    placement: 'group-data-[popper-placement*="right"]:-left-2 group-data-[popper-placement*="left"]:-right-2 group-data-[popper-placement*="top"]:-bottom-2 group-data-[popper-placement*="bottom"]:-top-2',
  },
}

const open = ref(props.modelValue)

const popper = computed(() => defu(props.popper, { placement: 'top' }) as any as PopperOptions)
</script>

<template>
  <UPopover data-ui="UiTooltipNext" :ui="ui" :open="open" class="flex items-center" :mode="mode" :popper="popper">
    <slot v-if="$slots.default" />
    <UIcon
      v-else
      name="i-heroicons-question-mark-circle"
      class="text-forgd-primary-300 w-5 h-5"
    />
    <template #panel>
      <div
        class="p-3 text-pretty bg-forgd-accent-900 font-display font-normal text-white text-xs space-y-2 text-center"
      >
        <slot name="panel" :close="() => open = false" />
      </div>
    </template>
  </UPopover>
</template>

